import React, { useEffect, useState } from "react";
import './styles.scss';
import TopImage from "../../../assets/images/toshiki-min-width.jpg";
import { MenuButton } from "../MenuButton";

type Content = {
  url: string;
  text: string;
};

const Profile: React.FC = () => {
  const contentItems: Content[] = [
    {
      url: '#about',
      text: 'ABOUT'
    },
    {
      url: '#media',
      text: 'MEDIA'
    },
    {
      url: '#contact',
      text: 'CONTACT'
    }
  ];

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = (): void => {
    setIsMenuOpen(!isMenuOpen);
  };

  const translateNavLinks = () => {
    return (
      contentItems.map(item => (
        <li key={item.url}>
          <a 
            href={item.url}
            style={{color: 'black'}} 
            onClick={() => toggleMenu()}
          >{item.text}</a>
        </li>
      ))
    )
  }

  const [animate, setAnimate] = useState<boolean>(false);

  useEffect(() => {
    // コンポーネントがマウントされた後、1秒後にアニメーションを開始する
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 1000);

    // コンポーネントがアンマウントされたらクリーンアップ
    return () => clearTimeout(timer);
  }, []);
  return (
    <section id="top" className={`top ${animate ? 'animate' : ''}`}>
      <div className='top-header'>
        <MenuButton
          open={isMenuOpen}
          controls="navigation"
          label="メニューを開きます"
          onClick={toggleMenu}
        />
        <nav id="navigation" aria-hidden={!isMenuOpen} className="navigation">
          <ul>
            {translateNavLinks()}
          </ul>
        </nav>
      </div>
      <div className="top-left">
        <div className="top-left-top">
          <p>手話通訳士(フリーランス)</p>
          <h1 className="top-title">Toshiki Hoshina</h1>
          <p className="top-subtitle">～手話をもっと身近に～</p>
        </div>
        <div className="top-left-bottom">
          {contentItems.map((item, i) => (
            <div key={i}>
              <p className="top-left-bottom-text">
                <a 
                  href={item.url}
                  style={{color: 'black'}} 
                  onClick={() => toggleMenu()}
                >{item.text}</a>
              </p>
              {i !== (contentItems.length - 1) && (
                <p className="top-left-bottom-border"></p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="top-detail-img">
        <img src={TopImage} alt="topImage"></img>
      </div>
    </section>
  );
};
export default Profile;