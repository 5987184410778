import React, { FC } from 'react';
import './styles.scss';
import TopImg from "../../../assets/images/backtotop.png"

type Props = {
  showBackToTop: boolean;
}

const BackToTopBtnComponent: FC<Props> = ({showBackToTop}) => {
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {showBackToTop ? (
        <button onClick={scrollToTop} className='back-to-botton'>
          <img src={TopImg} alt='topへ戻る画像'></img>
        </button>
      ) : null}
    </>
  );
};

export default BackToTopBtnComponent;
