import React, { useEffect, useState } from "react";
import './styles.scss';
import { Footer } from "../../uiParts/Footer";
import Profile from "../../uiParts/Profile";
import About from "../../uiParts/About";
import Media from "../../uiParts/Media";
import Contact from "../../uiParts/Contact";
import TopImg from "../../../assets/images/white-orange.png"
import BtmImg from "../../../assets/images/orange-white.png"
import BackToTopBtnComponent from "../../uiParts/BackToTopBtn";

const Home: React.FC = () => {
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = (): void => {
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop > 200) { // 200pxより上にスクロールされた場合に表示
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="container">
        <Profile />
        <img src={TopImg} alt="グラデーション画像" className="container-gradation-top"></img>
        <About />
        <img src={BtmImg} alt="グラデーション画像" className="container-gradation-bottom"></img>
        <Media />
        <img src={TopImg} alt="グラデーション画像" className="container-gradation-top"></img>
        <Contact />
        <BackToTopBtnComponent showBackToTop={showBackToTop} />
      </div>
      <Footer></Footer>
    </>
  );
};
export default Home;