import React from "react";
import './styles.scss';
import { useInView } from "react-intersection-observer";

const Media: React.FC = () => {
  const [ref, inView] = useInView({
    rootMargin: '-30% 0%',
    triggerOnce: true,
  })

  const mediaItems = [
    // {
    //   date: '2024-06-09',
    //   url: 'https://www.tokyoforward2025.metro.tokyo.lg.jp/entertainer/hoshina-toshiki/',
    //   text: 'test test test test test'
    // },
    {
      date: '2024-02-21',
      url: 'https://www.tokyoforward2025.metro.tokyo.lg.jp/entertainer/hoshina-toshiki/',
      text: '【TOKYO FOWRWARD 2025】で紹介していただきました！'
    },
    // {
    //   date: '2024-02-10',
    //   url: 'https://www.tokyoforward2025.metro.tokyo.lg.jp/entertainer/hoshina-toshiki/',
    //   text: '【TOKYO FOWRWARD 2025】で紹介していただきました！'
    // }
  ];

  const isNew = (getDate: string) => {
    const isDate = new Date(getDate);
    const today = new Date();
    
    isDate.setDate(isDate.getDate() + 14);
    isDate.setHours(0, 0, 0, 0); // 時刻を0に設定
    today.setHours(0, 0, 0, 0); // 時刻を0に設定

    if(isDate < today) {
      return false;
    } else {
      return true;
    }
  };

  const convertDate = (date: string) => {
    const isDate = new Date(date);
    return `${isDate.getFullYear()}年${isDate.getMonth() + 1}月${isDate.getDate()}日`
  };

  return (
    <section id="media" className='media'>
      <h1 className="media-title">MEDIA</h1>
      <div className={`media-table ${inView && 'media-show'}`} ref={ref}>
        {mediaItems.map((item, i) => (
          <div className="media-detail" key={i}>
            <p className="media-detail-date">{convertDate(item.date)}</p>
            {/* {isNew(item.date) && 
              <p className="media-detail-icon">New</p>
            } */}
            <p className="media-detail-url"><a href={item.url} target="_blank" rel="noreferrer" style={{color: '#000'}}>{item.text}</a></p>
          </div>
        ))}
      </div>
    </section>
  );
};
export default Media;