import React, { FC, MouseEventHandler } from 'react';
import "./styles.scss";

type Props = {
  open: boolean;
  onClick: MouseEventHandler;
  controls: string;
  label: string
}

export const MenuButton: FC<Props> = ({ open, controls, label, onClick }) => {
  return (
    <div className='header-menu'>
      <button
        type="button"
        aria-controls={controls}
        aria-expanded={open}
        aria-label={label}
        onClick={onClick}
        className="toggleButon"
      >
        <span className="line-1"></span>
        <span className="line-2"></span>
      </button>
    </div>
  );
};