import React from "react";
import './styles.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faInstagram, faSquareFacebook, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import BlockImage from "../../../assets/images/Portfolio_block.png";
import { useInView } from "react-intersection-observer";

type ContactItem = {
  label: string;
  detail: string;
};
type SnsItem = {
  link: string;
  icon: IconDefinition;
  iconColor: string;
  label: string;
};

const Contact: React.FC = () => {
  const contactItems: ContactItem[] = [
    {
      label: 'Email',
      detail: 't.hoshina0528@gmail.com'
    },
    {
      label: 'Social Media',
      detail: 'sns'
    }
  ];
  
  const snsItems: SnsItem[] = [
    {
      link: 'https://www.instagram.com/h.toshiki_',
      icon: faInstagram,
      iconColor: '#CF2E92',
      label: 'Instagram'
    },
    {
      link: 'https://twitter.com/7mLongjumper',
      icon: faXTwitter,
      iconColor: '#000',
      label: 'X'
    },
    {
      link: 'https://www.facebook.com/share/pE8cbpkhTicr2myS/?mibextid=LQQJ4d',
      icon: faSquareFacebook,
      iconColor: '#1877f2',
      label: 'Facebook'
    },
  ];

  const [ref, inView] = useInView({
    rootMargin: '-20% 0%',
    triggerOnce: true,
  })

  return (
    <section id="contact" className='contact'>
      <h1 className="contact-title">CONTACT</h1>
      <div className={`contact-container ${inView && 'contact-show'}`} ref={ref}>
        <div className="contact-container-left">
          <img src={BlockImage} alt="写真"></img>
        </div>
        <div className="contact-container-right">
            <div>
              {contactItems.map((item, i) => (
                <div className="contact-container-right-detail" key={i}>
                  <p className="contact-container-right-detail-label">{item.label}</p>
                  {item.detail !== 'sns' ? (
                    <p>{item.detail}</p>
                  ) : (
                    <div className="contact-container-right-sns">
                      {snsItems.map((item, i)=> (
                        <div className="contact-container-right-sns-icons" key={i}>
                          <a href={item.link} className="contact-container-right-sns-icons-icon"target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={item.icon} size="2x" color={item.iconColor} />
                          </a>
                          <p className="contact-container-right-sns-icons-text">{item.label}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
        </div>
      </div>
    </section>
  );
};
export default Contact;