import React from "react";
import './styles.scss';
import { useInView } from "react-intersection-observer";

const About: React.FC = () => {
  const [ref, inView] = useInView({
    rootMargin: '-30% 0%',
    triggerOnce: true,
  })
  return (
    <section id="about" className='about'>
      <div>
        <h1 className="about-title">ABOUT</h1>
        <div className={`about-detail ${inView && 'about-show'}`} ref={ref}>
          <p>
            1998年福島県福島市生まれ。<br/>
            <br/>
            亜細亜大学で手話を学び、フリーの手話通訳の道へ進むことを決意。<br/>
            2023年、厚生労働大臣認定手話通訳士資格を取得しました。<br/>
            <br/>
            スポーツや講演会、イベント、アート、企業、学術などの場面で活動しています。<br/>
            <br/>
            他にも手話通訳コーディネート、ナレーション、リポート、講演など、お気軽にご相談ください。
          </p>  
        </div>
      </div>
    </section>
  );
};
export default About;